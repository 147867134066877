html {
  /* background: url("z6c3UquP-wallha.com.png") no-repeat center center fixed;  */
  background: #000014;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 10;
  scroll-behavior: smooth;
}

body {
  /* background-color: 000014; */
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  color: aliceblue;
  color: aliceblue;
  /* font-family: "Andromeda-Bold"; */
}

text {
  color: aliceblue;
  font-family: "Rubik-Regular";
}

a {
  color: #a66cff;
}

.App-footer {
  position: flex;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.App-body {
  text-align: center;
  /* color: #000014; */
}

.footer-content {
  margin-bottom: 10px;
  /* color: #ffdfe0; */
  /* font-family: "Rubik-Regular"; */
}

.App {
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

.App-logo {
  margin-top: 10px;
  height: 18vmin;
  pointer-events: none;
}

.App-header {
  /* background-image: url("z6c3UquP-wallha.com.png"); */
  margin-top: 10%;
  background-color: #000014;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #ffdfe0;
  font-family: "Rubik-Regular";
}

.App-link {
  color: #23db07;
}

.about {
  margin-top: 20px;
  overflow-y: scroll;
}

.aboutBlock {
  margin-top: 20px;
}

.aboutBlockEnd {
  margin-top: 20px;
  margin-bottom: 20px;
}

.projects {
  max-height: calc(65vh - 50px);
  overflow-y: scroll;
}

.projectsNav:hover {
  text-decoration: underline;
  cursor: pointer;
}

.aboutNav:hover {
  text-decoration: underline;
  cursor: pointer;
}

.miscNav:hover {
  text-decoration: underline;
  cursor: pointer;
}

.projectBox {
  /* background-color: #0b55fb1d; */
  background-image: linear-gradient(55deg, #06112130, #0b53fb30, #30d1ee13, #f79a9d30);
  color: #ffdfe0;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  display: flex;
  width: 70vw;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  /* border: 2px solid rgba(255, 255, 255, 0.121); */
}

.aboutBox {
  /* background-color: #0b55fb1d; */
  background-image: linear-gradient(55deg, #06112130, #0b53fb30, #30d1ee13, #f79a9d30);
  color: #ffdfe0;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  display: flex;
  width: 70vw;
  max-width: 1000px;
  /* align-items: center;
  justify-content: center; */
  min-width: fit-content;
  text-align: left;
  /* border: 2px solid rgba(255, 255, 255, 0.121); */
}

.aboutBoxColumnImage {
  flex-direction: column;
  margin-left: 2%;
  margin-right: 3%;
  flex: 0;
  text-align: center;
  justify-content: center;
  margin-top: 3%;
  /* background-color: #978c8c43; */
}

.projectBoxColumnImage {
  flex-direction: column;
  margin-left: 2%;
  margin-right: 3%;
  flex: 0;
  text-align: center;
  justify-content: center;
  margin-top: 0%;
  /* width: 20%; */
  /* background-color: #978c8c43; */
}

.projectBoxColumnDescription {
  flex-direction: column;
  flex: 1;
  text-align: left;
  margin-top: 0%;
  align-items: top;
  justify-content: top;
  /* background-color: #978c8c43; */
}

.projectBoxColumnDescription p {
  margin: 0px;
  font-size: calc(10px + 2vmin / 3);
  /* font-family: "Rubik-Regular"; */
}

.projectBoxColumnDescription h2 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: calc(10px + 2vmin);
  /* font-family: "Rubik-Regular"; */
}

.projectLogo {
  /* margin: 5%; */
  /* margin-top: 1vw;
  margin-left: 1vw;
  margin-right: 1vw; */
  border-radius: 10px;
  width: 10vw;
  vertical-align: center;

  /* border: 2px solid rgba(255, 255, 255, 0.121); */
}

.projectLogoImg {
  margin-top: 0%;
  border-radius: 50%;
  width: 8vw;
  height: 8vw;
  max-height: 90px;
  max-width: 90px;
  vertical-align: center;
}

.projectLogoImg2 {
  margin-top: 0%;
  border-radius: 50%;
  mix-blend-mode: multiply;
  height: 4vw;
  max-height: 20px;
  max-width: 20px;
  margin-left: 3px;
  /* border: #ff000050 solid 2px; */
}

.projectDescription {
  font-size: 164px;
  font-weight: 200;
}

.projectDescriptionWithMargins {
  font-size: 16px;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aboutLogo {
  /* margin: 0px; */
  margin-top: 10%;
  /* margin-left: 10%;  */
  border-radius: 10px;
  width: 10vw;
  align-items: top;
  /* background-color: #978c8c43; */
}

.aboutLogoImg {
  margin-top: 10%;
  border-radius: 50%;
  width: 8vw;
  max-width: 180px;
}

.aboutBoxColumnDescription {
  flex-direction: column;
  flex: 1;
  text-align: left;
  margin-top: 0%;
  /* background-color: #978c8c43; */
}

.buttonNav {
  background-color: #0b55fb1d;
  background-image: linear-gradient(55deg, #06112140, #0b53fb40, #30d1ee26, #f79a9d42);
  color: #ffdfe0;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-decoration: none;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  font-family: "Rubik-Regular";
  display: flex;
  width: 70vw;
  max-width: 180px;
  /* border: 2px solid rgba(255, 255, 255, 0.121); */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #7c778560;
  padding-top: 10px;
  /* border-radius: 20px; */
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7c778584;
}

@font-face {
  font-family: "Andromeda-Bold";
  src: url(./Andromeda-Bold.otf) format("OpenType");
}

@font-face {
  font-family: "Andromeda";
  src: url(./Andromeda.otf) format("OpenType");
}

@font-face {
  font-family: "Rubik-Regular";
  src: url(./Rubik-Regular.ttf) format("TrueType");
}
